<template>
  <div class="image-upload d-flex justify-content-between">
    <div></div>
    <div>
      <input
        ref="file"
        accept="image/png, image/gif, image/jpeg"
        class="custom-file-input"
        :style="[
          this.url === null
            ? {
                backgroundImage: 'url(' + this.image + ')',
              }
            : {
                backgroundImage: 'url(' + this.url + ')',
              },
        ]"
        type="file"
        @change="onFileChange"
      />
      <p class="text-center" v-if="this.url === null">Select image to upload</p>
      <p class="text-center" v-else>{{ fileName }}</p>
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  props: {
    image: String,
  },
  data() {
    return {
      url: null,
      fileName: null,
      imageFile: null,
    };
  },
  methods: {
    onFileChange(e) {
      if (e.target.files[0] !== undefined) {
        this.imageFile = e.target.files[0];
        this.fileName = this.imageFile.name;
        this.url = URL.createObjectURL(this.imageFile);
      }
    },
  },
};
</script>
<style lang="css" scoped>
p {
  font-size: 10px;
}
.image-upload {
  padding: 30px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.custom-file-input {
  height: 150px;
  width: 150px;
  border-radius: 75px !important;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: contain;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  width: 100%;
}
.custom-file-input::before {
  content: "";
  display: inline-block;
  width: 100px;
  border-radius: 3px;
  outline: none;
  align-content: center;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  z-index: 9999;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: url("/edit_item.png") !important;
}
</style>
