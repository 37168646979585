<template>
  <div class="orders">
    <div class="cats-container" v-if="$store.state.teams.length > 0">
      <div @click="goback()" class="d-block d-sm-none">
        <button class="btn">
          <b-icon scale="1" style="color: #ad1f23" icon="chevron-left"></b-icon>
        </button>
      </div>
      <button
        @click="filter('type', 'All Orders')"
        :class="$store.state.filter.type === 'All Orders' ? 'selected' : ''"
        class="filter d-flex justify-content-between"
      >
        <p class="title">All Orders</p>
        <p class="d-none d-lg-block value">
          {{ $store.getters.getCount("All Orders") }}
        </p>
      </button>
      <button
        @click="filter('type', cat)"
        v-for="(cat, index) in $store.state.teams"
        :key="index"
        :class="$store.state.filter.type === cat.displayName ? 'selected' : ''"
        class="filter d-flex justify-content-between"
      >
        <p class="title">{{ cat.displayName }}</p>
        <p class="d-none d-lg-block value">
          {{ $store.getters.getCount(cat.displayName) }}
        </p>
      </button>
    </div>
    <transition v-if="!loading" name="slide-fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <div v-else>
      <b-spinner class="spinner"></b-spinner>
    </div>
  </div>
</template>

<script>
import ikebana from "../../services/ikebana";
export default {
  components: {},
  data() {
    return {
      teams: null,
      loading: false,
    };
  },
  methods: {
    goback() {
      this.$router.push({ path: "/dashboard" });
    },
    filter(type, value) {
      this.$store.commit("FILTER", {
        type: type,
        value: value === "All Orders" ? "All Orders" : value.displayName,
      });
      window.scrollTo(0, 0);
      if (this.$route.name !== "Order List") {
        this.$router.go(-1);
      }
    },
  },
  created: function () {
    this.loading = true;
    ikebana
      .getOrders({
        filter: "All Orders",
        client: null,
        page: 1,
        size: 1000,
      })
      .then(
        (response) => {
          this.$store.dispatch("SET_ORDER_LIST", response.data);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  },
};
</script>
<style lang="css" scoped>
.cats-container p {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .cats-container p {
    font-size: 10px !important;
    text-align: start !important;
  }
}

.cats-container {
  position: sticky !important;
  top: 0px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100% !important;
  align-items: center;
  padding-left: 5px;
  overflow: auto;
  z-index: 9;
  background: white;
}

.filter {
  padding: 5px;
  position: relative;
  border-radius: 12px;
  width: 100%;
  height: 60px;
  text-align: center;
  background: #ffffff;
  padding-top: 15px;
  cursor: pointer;
}

.filter p {
  color: #ad1f23;
}

.title {
  width: 50px;
  font-size: 12px;
  text-align: start;
}
.value {
  font-size: 25px !important;
  font-weight: bold;
}

.selected {
  background: #ad1f23 !important;
  -webkit-transition: background 200ms ease-in;
  -ms-transition: background 200ms ease-in;
  transition: background 200ms ease-in;
}

.selected .title {
  color: white !important;
}

.selected .value {
  color: white !important;
}
</style>
