<template>
  <div v-if="!loading" class="edit-profile">
    <div v-if="user" class="info">
      <div class="row">
        <div style="min-height: 95vh" class="col-md bg">
          <div class="d-flex justify-content-between">
            <h3 class="p-3">Your Profile</h3>
            <div style="padding-top: 10px" class="col-md-2">
              <button class="btn destructive" @click="logout">Logout</button>
            </div>
          </div>
          <div class="left">
            <div class="row">
              <div class="col-md">
                <div class="d-flex p-2">
                  <img
                    class="rounded-circle"
                    width="100"
                    height="100"
                    :src="user.avatar"
                    alt=""
                  />
                  <div class="p-2">
                    <h5>{{ user.firstName }} {{ user.lastName }}</h5>
                    <p>
                      Designation: <strong>{{ user.designation }}</strong>
                      <br />
                      Role: <strong>{{ user.role }}</strong>
                      <br />
                      Email:
                      <strong>{{ user.emailAddress }}</strong>
                      <br />
                      Contact:
                      <strong>{{ user.phoneNumber }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <h6 class="pt-2">Change Password</h6>
                <form @submit.prevent>
                  <div class="form-row">
                    <input
                      v-model="changePassword.currentPassword"
                      type="password"
                      placeholder="Current Password"
                      name="oldPassword"
                      required
                    />
                  </div>
                  <div class="form-row">
                    <input
                      v-model="changePassword.newPassword"
                      type="password"
                      placeholder="New Password"
                      name="oldPassword"
                      required
                    />
                  </div>
                  <div class="form-row">
                    <input
                      v-model="changePassword.confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      name="oldPassword"
                      required
                    />
                  </div>
                  <button
                    :disabled="password_loading"
                    class="btn primary auth-btn"
                    type="submit"
                    @click="changePasswordSubmit"
                  >
                    <span
                      v-show="password_loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span v-show="!password_loading">Change Password</span>
                  </button>
                </form>
              </div>
              <div class="col-md"></div>
            </div>
          </div>

          <div
            v-for="(device, index) in user.devices"
            class="card-group"
            :key="index"
          >
            {{ device.name }}
          </div>
        </div>
        <div class="col-md-4 bg p-2">
          <div class="row">
            <h6 class="pt-2">Your Teams</h6>
            <div class="d-flex">
              <!-- <button @click="addTeam" style="background: transparent; color: grey">
            <b-icon scale="1.5" icon="plus"></b-icon>
          </button> -->
            </div>
            <div class="p-2">
              <div
                class="team-item"
                :style="[
                  {
                    background: element.hex,
                  },
                ]"
                v-for="(element, index) in user.teams"
                :key="index"
              >
                <div v-if="element.name">
                  {{ element.name }}
                </div>
                <div v-else>
                  <input
                    type="text"
                    name="teamName"
                    placeholder="Team name"
                    v-model="newTeamName"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-spinner v-else class="spinner"></b-spinner>
</template>
<script>
import ikebana from "../../services/ikebana";
import fcm from "../../firebase";

export default {
  data() {
    return {
      user: null,
      loading: false,
      password_loading: false,
      changePassword: {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  methods: {
    async logout() {
      var token = await fcm.getToken();
      await ikebana.logout({
        fcm: token,
      });
      this.$store.dispatch("LOGOUT");
      this.$router.push("/auth/login");
    },
    changePasswordSubmit() {
      this.password_loading = true;
      ikebana.changePassword(this.changePassword).then(
        (response) => {
          this.password_loading = false;
          if (response.data) {
            this.SET_USER(response.data);
          }

          this.changePassword.currentPassword = null;
          this.changePassword.newPassword = null;
          this.changePassword.confirmPassword = null;
        },
        (error) => {
          this.password_loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
          this.changePassword.currentPassword = null;
          this.changePassword.newPassword = null;
          this.changePassword.confirmPassword = null;
        }
      );
    },
  },
  mounted: function () {
    this.loading = true;
    ikebana.getUser().then(
      (response) => {
        this.user = response.data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: error.response.data.Message,
        });
      }
    );
  },
};
</script>
<style lang="css" scoped>
.form-row {
  margin-bottom: 10px;
}
.edit-profile {
  text-align: start;
  padding: 10px;
  border-radius: 12px;
  height: 100% !important;
}

img {
  margin-top: 15px;
}
input {
  width: 100%;
  height: 50px;
}
.team-item {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.bg {
  background: white;
  border-radius: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
