<template>
  <div @contextmenu.prevent id="app">
    <router-view></router-view>
    <!-- <div @click="refreshApp" v-show="updateExists">An update is available</div> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import update from "./mixins/update";
import fcm from "./firebase";
import ikebana from "./services/ikebana";
export default {
  data() {
    return {
      notification: [],
    };
  },
  mixins: [update],
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    read(notification, index) {
      this.$router.push(notification.url);
      this.notification.splice(index, 1);
    },
  },
  mounted: function () {
    var user = this.$store.getters.getUser;
    if (user === null) {
      return;
    }
    fcm.getToken().then((response) => {
      if (response) {
        user.fcm = response;
      }
      user.fcm = response;
      console.log(response);
      ikebana.refresh(user);
      navigator.serviceWorker.addEventListener("message", (event) => {
        var message = event.data.notification;
        var orderId = event.data.data["orderId"];
        if (orderId) {
          ikebana.getOrder(orderId).then((res) => {
            this.$store.dispatch("UPDATE_ORDER", res.data);
            this.$store.state.notifications.push(message);
            this.$notify({
              duration: 10000,
              group: "warning",
              title: message.title,
              text: message.body,
            });
          });
        } else {
          this.$store.state.notifications.push(message);
          this.$notify({
            duration: 10000,
            group: "warning",
            title: message.title,
            text: message.body,
          });
        }
      });
    });
  },
  watch: {
    isLoggedIn() {},
  },
  created: async function () {},
};
</script>

<style>
.notification-container {
  position: absolute;
  z-index: 9999999999;
  bottom: 0;
  left: 0;
}

.multiselect__placeholder {
  padding-top: 5px;
}

.multiselect__tags {
  border-radius: 12px;
  min-height: 50px !important;
}
.multiselect__placeholder {
  display: inline-block !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.multiselect.invalid .multiselect__tags {
  border: 1px solid #ad1f23 !important;
}

.multiselect__option--highlight {
  background: #428bca !important;
}

.multiselect__option--highlight:after {
  background: #428bca !important;
}

.multiselect__tags {
  padding: 5px !important;
  min-height: 10px;
}

.multiselect__placeholder {
  margin-left: 10px;
  margin-top: 2px;
}

.multiselect__input {
  height: auto !important;
  z-index: 9999;
}

.order-tags {
  margin-bottom: 10px;
}
.tag {
  background: #008cf6;
  color: white;
  font-size: 12px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px !important;
  width: auto !important;
}
textarea {
  border-radius: 12px;
  padding: 10px;
  resize: none;
  width: 100%;
}
.tag button {
  background: transparent;
  color: white;
  padding: 0px;
}

.tag-input button {
  background: transparent;
  color: white;
  padding: 0px;
}

.tag-input {
  color: transparent;
  font-size: 12px;
  padding-right: 1px !important;
  padding-left: 1px !important;
  padding-bottom: 1px !important;
  padding-top: 1px !important;
  margin-right: 1px !important;
  margin-bottom: 5px;
  border-radius: 5px;
  width: auto !important;
}

.tag-input input {
  border-radius: 5px !important;
  height: 22px;
}
.multiselect__option--highlight {
  background: #ccc;
}
.notification {
  background: #ccc;
  margin-bottom: 10px;
  width: 200px;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.btn {
  width: 100% !important;
  margin-bottom: 10px;
}
.form-content input {
  margin-bottom: 10px;
  height: 50px;
  color: #ad1f23;
}
.table-header tr {
  font-weight: bold;
  font-size: 14px !important;
}
.end button {
  background: none;
  color: black;
  border: none;
}
.spinner {
  color: #ad1f23;
  position: absolute;
  top: 50%;
  width: 3rem;
  height: 3rem;
}
.end button:hover {
  background: none;
  color: #ad1f23;
}
.table {
  border-radius: 12px;
  padding: 10px !important;
  vertical-align: middle;
  color: #707070;
  background: transparent;
  border-collapse: separate;
  border-spacing: 0 10px;
}
thead tr {
  background: transparent !important;
  height: auto !important;
}
tr {
  text-align: start;
  border: none !important;
  cursor: pointer;
  font-size: 14px;
  background: white;
  vertical-align: middle;
  height: 50px;
}

td {
  border: none !important;
}
.heading {
  text-align: start;
  font-size: 16px;
  color: #ad1f23 !important;
}
.b-sidebar {
  width: 40vw !important;
}
@media (max-width: 768px) {
  .b-sidebar {
    width: 100vw !important;
  }
  #top-controls {
    display: block !important;
  }
  .body {
    margin-top: 10px !important;
  }
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
body {
  background: #ececec !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  height: 100%;
}
button,
input {
  border-radius: 12px !important;
  border: none;
}

.primary {
  background: #008cf6 !important;
  color: white !important;
}
.destructive {
  border: 1px solid #d40007 !important;
  color: #d40007 !important;
}
input {
  border: 1px solid #ebebeb;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.body {
  min-height: 100vh !important;
  margin-top: 10px;
}

.footer {
  font-size: 10px;
  text-align: center;
}

p {
  color: #707070;
}

button:focus,
button:active:focus,
button.active:focus {
  box-shadow: none !important;
}
input:focus,
input:active:focus,
input.active:focus {
  box-shadow: none !important;
}
*:focus {
  outline: none;
}

h1,
h1,
h3,
h4,
h5,
h6 {
  color: #ad1f23 !important;
}

[v-cloak] .v-cloak--block {
  display: block;
}
[v-cloak] .v-cloak--inline {
  display: inline;
}
[v-cloak] .v-cloak--inlineBlock {
  display: inline-block;
}
[v-cloak] .v-cloak--hidden {
  display: none;
}
[v-cloak] .v-cloak--invisible {
  visibility: hidden;
}
.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
  display: none;
}
</style>
