<template>
  <transition name="slide-fade" mode="in-out">
    <tr
      v-if="$store.getters.getListStyle === 'extended'"
      class="order"
      ref="order"
      :class="[
        this.first ? 'first-row' : '',
        this.start !== null ? 'action-started' : 'action-not-started',
      ]"
    >
      <div @click="viewOrder" style="border-radius: 15px">
        <div>
          <div class="d-flex justify-content-between">
            <div v-if="order.client" class="d-flex">
              <img
                style="border-radius: 25px; margin-right: 5px"
                :src="order.client.logo"
                height="50"
                width="50"
                alt=""
              />
              <p style="text-align: start">
                Client:
                <button @click.stop="viewClientOrders">
                  <strong>{{ order.client.name }}</strong>
                </button>
                <br />
                PO Number:
                <strong>{{ order.po === "null" ? "_" : order.po }}</strong>
                <br />
                Delivery Note: <strong>{{ order.dnId }}</strong>
                <br />
                <span v-if="order.correspondents">
                  Correspondents:
                  <strong
                    v-for="(correspondent, index) in order.correspondents"
                    :key="correspondent.id"
                    >{{
                      index === 0
                        ? correspondent.name
                        : ", " + correspondent.name
                    }}</strong
                  >
                </span>
                <br />
                <span style="font-size: 14px; color: #ad1f23; text-align: end">
                  <span class="tag" style="background: #ad1f23">
                    Due: <strong> {{ order.dueDate | formatDate }}</strong>
                  </span>
                </span>
                <br />
                <span style="font-size: 14px; color: #ad1f23; text-align: end">
                  <span class="tag" style="background: #ffa500; color: black">
                    Mode:<strong> {{ order.transportMethod }}</strong>
                  </span>
                </span>
              </p>
            </div>
            <div>
              <div class="order-status-tag">
                {{
                  order.team.index === 1
                    ? order.team.displayName
                    : order.team.displayName.split(" ")[1]
                }}
              </div>
              <div
                style="margin-top: 10px"
                class="order-tags"
                v-if="order.tags"
              ></div>
            </div>
          </div>
          <div class="d-flex justify-content-between tags">
            <div></div>
            <div v-if="order.tags" class="d-flex">
              <button
                @click.stop="addTags(tag)"
                class="tag"
                v-for="(tag, index) in order.tags.split(',')"
                :key="index"
              >
                {{ tag }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between"></div>
        <div class="order-item-list">
          <div
            v-for="(item, index) in order.items"
            :key="index"
            class="d-flex justify-content-between ordered-item"
          >
            <div style="width: 100%" class="d-flex">
              <img
                class="rounded-circle"
                width="40"
                height="40"
                :src="item.product.image"
                alt=""
              />
              <p style="text-align: start; padding-left: 10px">
                {{ item.product.name }} - {{ item.productSize }}
              </p>
            </div>
            <p style="text-align: end">Qty: {{ item.qty }} {{ item.unit }}</p>
          </div>
        </div>
      </div>
    </tr>
    <tr @click="viewOrder" class="order-min" v-else>
      <th>
        <img :src="order.client.logo" width="50" height="50" />
        {{ order.client.name }}
      </th>
      <td>
        <strong>{{ order.po === "null" ? "_" : order.po }}</strong>
      </td>
      <td>
        <strong>{{ order.dnId }}</strong>
      </td>
      <td>
        <div v-if="order.tags" class="d-flex">
          <button
            @click.stop="addTags(tag)"
            class="tag"
            v-for="(tag, index) in order.tags.split(',')"
            :key="index"
          >
            {{ tag }}
          </button>
        </div>
      </td>
      <td>
        <span style="font-size: 14px; color: #ad1f23; text-align: end">
          <span class="tag" style="background: #ffa500; color: black">
            Mode:<strong> {{ order.transportMethod }}</strong>
          </span>
        </span>
      </td>
      <td>
        <span style="font-size: 14px; color: #ad1f23; text-align: end">
          <span class="tag" style="background: #ad1f23">
            Due: <strong> {{ order.dueDate | formatDate }}</strong>
          </span>
        </span>
      </td>
      <td>
        <div class="order-min-status-tag">
          {{
            order.team.index === 1
              ? order.team.displayName
              : order.team.displayName.split(" ")[1]
          }}
        </div>
      </td>
    </tr>
  </transition>
</template>
<script>
export default {
  components: {},
  props: {
    order: Object,
    first: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tags: [],
      status: null,
      start: null,
      loading: false,
      end: null,
      style: this.$store.getters.listStyle,
    };
  },
  methods: {
    addTags(tag) {
      this.$store.commit("ADD_FILTER_TAGS", tag);
    },
    hide() {
      this.$refs.sidebar.close();
    },
    delivered() {},
    viewClientOrders() {
      this.$router.push({
        name: "Client Orders",
        params: {
          id: this.order.client.id,
        },
      });
    },
    viewOrder() {
      this.$router.push({
        name: "Order Details",
        params: {
          id: this.order.id,
        },
      });
    },
    filter(type, value) {
      this.$store.commit("FILTER", {
        type: type,
        value: value,
      });
    },
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getDisplayText() {
      if (this.$store.state.filter.type === "All Orders") {
        return this.order.team.displayName;
      }

      if (this.order.team.index === 1) {
        return (
          "Send to " +
          this.$store.state.teams[this.order.team.index].displayName.split(
            " "
          )[1]
        );
      } else {
        var status = this.$store.state.teams[this.order.team.index - 1];
        return "Start " + status.displayName.split(" ")[1];
      }
    },
  },
  mounted: function () {},
};
</script>
<style lang="css" scoped>
.order-min {
  background: white;
  margin-bottom: 8px;
  text-align: start !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
}

.order-min .image {
  height: 35px;
  width: 35px;
  border-radius: 17.5px;
}
.tags {
  display: flex;
}
.order-status-tag {
  background: #444444;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 12px;
  padding: 10px 20px 10px 20px;
  max-width: 150px;
  text-align: center;
  margin-left: auto !important;
  border-radius: 5px;
}
.order-min-status-tag {
  background: #444444;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  max-width: 100px;
  text-align: center;
  margin-left: auto !important;
  border-radius: 5px;
}
.ordered-item {
  padding: 5px;
  border-radius: 15px;
  background: #e9e9e9;
  margin-bottom: 5px;
}
.order {
  border: 1px solid white;
  cursor: pointer;
  background: white;
  border-radius: 12px;
  margin-bottom: 10px;
}
.order:hover {
  border: 1px solid #ad1f23;
}
.order-status {
  padding: 10px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  align-content: center;
  color: black;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
</style>
