import http from "axios";
import store from "@/store";
http.interceptors.request.use(
  function (config) {
    config.baseURL =
      process.env.NODE_ENV === "production"
        ? "https://ikebana.flowermaldives.com"
        : "http://localhost:62008";
    const token = store.getters.getUser?.token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

class Ikebana {
  login(user) {
    return http.post("/api/auth/login", user);
  }
  logout(user) {
    return http.post("/api/auth/logout", user);
  }
  forgot(email) {
    return http.post("/api/auth/forgot", {
      emailAddress: email,
    });
  }
  verify(email, code) {
    return http.get("/api/auth/confirmation?id=" + email + "/&code=" + code);
  }
  reset(payload) {
    return http.post("/api/auth/reset", payload);
  }
  changePassword(payload) {
    return http.post("/api/auth/change", payload);
  }
  refresh(user) {
    return http.post("/api/auth/refresh", user);
  }
  //HTTP GET
  getClients() {
    return http.get("/api/client/list");
  }
  getItems() {
    return http.get("/api/product/list");
  }
  getOrder(id) {
    return http.get("/api/order/find/" + id);
  }
  exportToExcel(from, to) {
    return http.get("/api/order/list/export/excel/" + from + "/" + to, {
      responseType: "blob",
    });
  }
  getOrders(payload) {
    var url = "/api/order/list";
    if (payload.filter) {
      url = url + "?filter=" + payload.filter;
    }

    if (payload.client) {
      url = url + "&clientId=" + payload.client;
    }

    if (payload.page) {
      url = url + "&pageNumber=" + payload.page;
    }

    if (payload.size) {
      url = url + "&pageSize=" + payload.size;
    }

    if (payload.from) {
      url = url + "&from=" + payload.from;
    }

    if (payload.to) {
      url = url + "&to=" + payload.to;
    }

    return http.get(url);
  }
  getUsers() {
    return http.get("/api/user/list");
  }
  getUser() {
    return http.get("/api/user/get");
  }
  getTeams() {
    return http.get("/api/order/teams");
  }

  //HTTP POST
  addOrder(data) {
    return http.post("/api/order/create", data);
  }
  updateOrder(id, data) {
    return http.post("/api/order/update/" + id, data);
  }
  addNewItem(data) {
    return http.post("/api/product/create", data);
  }
  addNewClient(data) {
    return http.post("/api/client/create", data);
  }
  addNewCorrespondent(id, data) {
    return http.post("/api/client/" + id + "/add/correspondent", data);
  }
  addNewUser(data) {
    return http.post("/api/user/create", data);
  }

  //HTTP PUT
  next(id, currentStage) {
    return http.post("/api/order/" + id + "/next/" + currentStage);
  }
  markDelivered(id, data) {
    return http.post("/api/order/" + id + "/delivered", data);
  }
  finishedPacking(id, data) {
    return http.post("/api/order/" + id + "/packed", data);
  }
  addNote(id, note, isPublic) {
    return http.put("/api/order/update/" + id + "/note", {
      note: note,
      isPublic: isPublic,
    });
  }
  addTag(id, tag) {
    return http.put("/api/order/update/" + id + "/tag", {
      tag: tag,
    });
  }
  printLabel(id) {
    return http.put("/api/order/" + id + "/print");
  }
  removeTag(id, tag) {
    return http.delete("/api/order/delete/" + id + "/tag/" + tag);
  }
  shareTracking(id) {
    return http.put("/api/order/" + id + "/share/tracking");
  }
  cancelOrder(id, note) {
    return http.put("/api/order/update/" + id + "/cancel", {
      note: note,
    });
  }
  addImages(id, images) {
    return http.put("/api/order/update/" + id + "/image", images);
  }
  deleteImage(id, imageId) {
    return http.delete(
      "/api/order/update/" + id + "/image/" + imageId + "/remove"
    );
  }
  editItem(id, data) {
    return http.post("/api/product/update/" + id, data);
  }
  editUser(id, data) {
    return http.put("/api/user/update/" + id, data);
  }
  editClient(id, data) {
    return http.post("/api/client/update/" + id, data);
  }
  editCorrespondent(id, data) {
    return http.post("/api/client/" + id + "/edit/correspondent", data);
  }

  //HTTP DELETE
  deleteItem(id) {
    return http.delete("/api/product/delete/" + id);
  }
  deleteclient(id) {
    return http.delete("/api/client/delete/" + id);
  }
  deletecorrespondent(clientId, correspondentId) {
    return http.delete(
      "/api/client/" + clientId + "/remove/correspondent/" + correspondentId
    );
  }
  deleteUser(id) {
    return http.delete("/api/user/delete/" + id);
  }
  downloadDn(path) {
    return http.get(path, { responseType: "blob" });
  }
}

export default new Ikebana();
