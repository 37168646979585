<template>
  <div>
    <div class="client-order-list-top">
      <div class="d-flex justify-content-between">
        <div @click="goback()" class="d-block d-sm-none">
          <button class="btn">
            <b-icon
              scale="1"
              style="color: #ad1f23"
              icon="chevron-left"
            ></b-icon>
          </button>
        </div>
        <div class="route-title">
          <h5>{{ this.$route.name }}</h5>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px" class="row">
      <div class="col-md-4 advance-filter">
        <div class="d-flex">
          <button
            @click="$store.commit('REMOVE_FILTER_TAGS', tag)"
            v-for="(tag, index) in tags"
            :key="index"
            class="tag"
          >
            {{ tag }}
          </button>
          <div class="d-flex justify-content-between tag-input">
            <transition name="slide-fade" mode="out-in">
              <input
                @keyup.esc="endTagging"
                @keyup.enter="saveTags"
                @submit="saveTags"
                v-if="taggable"
                v-model="new_tag"
                type="text"
              />
              <button
                style="
                  width: 20px !important;
                  background: #008cf6;
                  border-radius: 5px !important;
                "
                @click="startTagging"
                v-else
              >
                <b-icon icon="plus"></b-icon>
              </button>
            </transition>
          </div>
        </div>
      </div>
      <div style="padding-left: 0" class="search col-md">
        <div style="margin-right: 10px" class="row ms-auto">
          <input
            class="col-md"
            name="from"
            type="date"
            placeholder="From"
            v-model="from"
          />
          <input class="col-md" type="date" placeholder="To" v-model="to" />
          <input
            class="col-md"
            type="text"
            placeholder="Search"
            v-model="search"
          />

          <!-- <div>
            <button class="col-md">
              <b-icon icon="filter"></b-icon>
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="!loading" class="order-list">
      <transition el="div" name="slide-fade" mode="out-in">
        <OrderList :list="orders"></OrderList>
      </transition>
    </div>
    <b-spinner v-else class="spinner"></b-spinner>
  </div>
</template>
<script>
import OrderList from "../../components/OrderList.vue";
import ikebana from "../../services/ikebana";
export default {
  props: {
    id: Number,
  },
  components: { OrderList },
  data() {
    return {
      from: localStorage.getItem("from"),
      to: localStorage.getItem("to"),
      toggleFilter: false,
      orders: [],
      tags: [],
      loading: false,
      search: "",
      filteredOrders: null,
      taggable: false,
      new_tag: null,
    };
  },

  computed: {
    getCount() {
      return this.orders.length;
    },
    // getOrdersFilteredByTags(){
    //   return this.orders.filter((o) => {
    //     return o.tags.
    //   })
    // }
    getOrders() {
      // return this.users.filter((user) => {
      //   return JSON.stringify(user).match(this.search.toLowerCase());
      // });
      // if (this.search === "") {
      //   return this.orders;
      // }
      var orders = this.orders.filter((o) => {
        return JSON.stringify(o).toLowerCase().match(this.search.toLowerCase());
      });
      // const computed = products.filter(product => product.categories.every(item => checked.includes(item) || !checked.length));
      if (this.tags.length > 0) {
        var filteredOrders = orders
          .filter((x) => x.tags !== null)
          .filter((order) => {
            var orderTags = order.tags.split(",");
            return this.tags.every((filterTags) => {
              return orderTags.includes(filterTags);
            });
          });

        return filteredOrders;
      }
      return orders;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    orders: function () {},
    from: function (a, b) {
      if (a !== b) {
        this.loading = true;
        ikebana
          .getOrders({
            filter: "All Orders",
            client: this.$route.params.id,
            page: 1,
            size: 100000,
            from: this.from,
            to: this.to,
          })
          .then(
            (response) => {
              this.orders = response.data;
              this.loading = false;
            },
            (error) => {
              var err = error.response.data;
              this.$notify({
                duration: 5000,
                group: "error",
                title: err.Message,
                text: err.Data,
              });
              this.loading = false;
            }
          );
      }
      localStorage.setItem("from", a);
    },
    to: function (a, b) {
      localStorage.setItem("to", a);
      if (a !== b) {
        this.loading = true;
        ikebana
          .getOrders({
            filter: "All Orders",
            client: this.$route.params.id,
            page: 1,
            size: 100000,
            from: this.from,
            to: this.to,
          })
          .then(
            (response) => {
              this.orders = response.data;
              this.loading = false;
            },
            (error) => {
              var err = error.response.data;
              this.$notify({
                duration: 5000,
                group: "error",
                title: err.Message,
                text: err.Data,
              });
              this.loading = false;
            }
          );
      }
    },
    // "$this.$store.getters.getOrders": function () {
    //   this.orders = this.$store.getters.getOrders;
    // },
    // eslint-disable-next-line no-unused-vars
    "$store.state.filter.type": function (newFilter, oldFilter) {
      this.search = "";
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.tags": function (newFilter, oldFilter) {
      this.tags = newFilter;
      window.scrollTo(0, 0);
    },
    // "$store.state.orders": function (a) {
    //   this.orders = a;
    // },
  },
  methods: {
    startTagging() {
      this.taggable = true;
    },
    endTagging() {
      this.taggable = false;
    },
    saveTags() {
      this.$store.commit("ADD_FILTER_TAGS", this.new_tag);
      this.new_tag = null;
      this.endTagging();
    },
  },
  mounted: function () {
    this.loading = true;
    ikebana
      .getOrders({
        filter: "All Orders",
        client: this.$route.params.id,
        page: 1,
        size: 10000,
        from: this.from,
        to: this.to,
      })
      .then(
        (response) => {
          this.orders = response.data;
          this.loading = false;
        },
        (error) => {
          var err = error.response.data;
          this.$notify({
            duration: 5000,
            group: "error",
            title: err.Message,
            text: err.Data,
          });
          this.loading = false;
        }
      );
  },
};
</script>
<style lang="css">
.client-order-list-top {
  width: 100%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0px;
  vertical-align: middle !important;
  align-content: center !important;
  background: white;
  border-radius: 12px;
  padding: 10px;
}

.route-title {
  vertical-align: middle !important;
}
.search-box {
  width: auto !important;
}
.search {
  display: flex;
  flex-wrap: wrap !important;
}

.search input {
  height: 40px;
  margin-left: 10px;
}
.loading {
  background: white;
  height: 79vh;
}
.advance-filter {
  margin-top: 10px;
}
.cats-container p {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .cats-container p {
    font-size: 10px !important;
    text-align: start !important;
  }

  .cats-container {
    top: 10px !important;
  }
}

.cats-container {
  position: sticky !important;
  top: 0px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  padding-left: 5px;
  overflow: auto;
  background: white;
}

.filter {
  padding: 5px;
  position: relative;
  border-radius: 12px;
  width: 100%;
  height: 60px;
  text-align: center;
  background: #ffffff;
  padding-top: 15px;
  cursor: pointer;
}

.filter p {
  color: #ad1f23;
}

.title {
  width: 50px;
  font-size: 12px;
  text-align: start;
}
.value {
  font-size: 25px !important;
  font-weight: bold;
}

.selected {
  background: #ad1f23 !important;
  -webkit-transition: background 200ms ease-in;
  -ms-transition: background 200ms ease-in;
  transition: background 200ms ease-in;
}

.selected .title {
  color: white !important;
}

.selected .value {
  color: white !important;
}

.remove {
  background: none;
}
ul {
  list-style: none;
}
ul li {
  border-radius: 8px;
  display: inline;
  margin-left: 10px;
}
.empty {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  position: relative;
  height: 80vh;
}

.empty img {
  background-size: cover;
  background-position: center;
}

.empty h6 {
  vertical-align: middle;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.new-order button {
  border-radius: 12px;
  width: 70px;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 35px;
  background: #ffffff;
  margin-left: 5px;
  cursor: pointer;
  color: #ad1f23;
}
.order-header p {
  padding-left: 50px;
}

.order-list p {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
</style>
