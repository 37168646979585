<template>
  <div class="edit-client">
    <form class="edit-client-form" @submit.prevent="addNewItem">
      <div>
        <input
          type="text"
          class="form-control"
          placeholder="Client Name"
          required
          v-model="correspondent.name"
        />
        <div class="d-flex">
          <input
            required
            type="text"
            style="margin-right: 10px"
            v-model="correspondent.designation"
            class="form-control"
            placeholder="Designation"
          />
          <input
            type="text"
            class="form-control"
            v-model="correspondent.email"
            required
            placeholder="Email"
          />
        </div>
        <input
          type="text"
          class="form-control"
          v-model="correspondent.phoneNumber"
          placeholder="Phone Number"
        />
        <input
          type="text"
          class="form-control"
          v-model="correspondent.department"
          placeholder="Department"
        />
      </div>
      <div class="d-flex">
        <button
          style="margin-right: 5px"
          :disabled="loading"
          class="btn primary"
          @click="addNewClient"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Save</span>
        </button>
        <button
          type="button"
          style="margin-right: 5px"
          class="btn destructive"
          @click="onCancel"
        >
          <span v-show="!loading">Cancel</span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import ikebana from "../../services/ikebana";

export default {
  props: {
    client: Object,
    correspondent: Object,
  },
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    addNewClient() {
      this.loading = true;
      ikebana.editCorrespondent(this.client.id, this.correspondent).then(
        (response) => {
          this.correspondent = response.data;
          this.$emit("hide");
        },
        (error) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    onCancel() {
      this.$emit("hide");
    },
  },
};
</script>
<style lang="css"></style>
