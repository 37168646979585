<template>
  <div v-if="!loading" class="d-flex">
    <div
      v-if="!$store.state.loading.users"
      class="col-md"
      style="
        background: white;
        padding: 10px;
        border-radius: 15px;
        margin-right: 5px;
        height: 100% !important;
        min-height: 97vh;
      "
    >
      <div class="d-flex justify-content-between">
        <h6 class="pt-2 text-start">Users</h6>
        <input
          style="height: 50px"
          type="text"
          placeholder="Search Users"
          v-model="search"
        />
      </div>
      <div class="row">
        <User
          v-for="(user, index) in searchUsers"
          :key="index"
          :first="index === 0"
          :user="user"
        ></User>
      </div>
    </div>
    <div class="col-md-3">
      <div
        style="
          top: 90px;
          position: sticky;
          top: 0;
          background: white;
          border-radius: 15px;
          border-right: 1px solid #ccc;
          margin-right: 5px;
        "
      >
        <div class="mx-2 d-flex justify-content-between">
          <h6 class="pt-2">Teams</h6>
          <!-- <button @click="addTeam" style="background: transparent; color: grey">
            <b-icon scale="1.5" icon="plus"></b-icon>
          </button> -->
        </div>
        <div class="p-2">
          <draggable v-model="teams" @start="start" @end="end">
            <div
              class="team-item"
              :style="[
                {
                  background: element.hexCode,
                },
              ]"
              v-for="(element, index) in $store.state.teams"
              :key="index"
            >
              <div v-if="element.name">
                {{ element.name }}
              </div>
              <div v-else>
                <input
                  type="text"
                  name="teamName"
                  placeholder="Team name"
                  v-model="newTeamName"
                  @change="
                    element.name = newTeamName;
                    element.index = index;
                    element.displayName = newTeamName;
                    newTeamName = null;
                  "
                />
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
  <div v-else>Loading</div>
</template>
<script>
import draggable from "vuedraggable";
import User from "./User.vue";

export default {
  components: { draggable, User },
  data() {
    return {
      users: [],
      search: "",
      newTeamName: null,
      teams: [],
      loading: true,
    };
  },

  methods: {
    start() {},
    end() {},
    addTeam() {
      this.teams.push({
        id: 0,
        name: null,
        index: 1,
        displayName: "",
        hexCode: "#ad1f23",
        shown: true,
        orders: null,
        users: null,
        tags: null,
      });
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    searchUsers() {
      if (this.search === "") {
        return this.$store.state.users;
      }
      return this.$store.state.users.filter((user) => {
        return JSON.stringify(user).match(this.search.toLowerCase());
      });
    },
  },
  watch: {
    teams() {},
    users() {
      this.loading = false;
    },
  },
  mounted: function () {
    this.teams = this.$store.state.teams;
    this.users = this.$store.state.users;
  },
};
</script>
<style lang="css" scoped>
.team-item {
  cursor: pointer;
  text-align: start;
  padding: 10px;
  transition: all 0.2s;
  border-radius: 12px;
  margin-bottom: 5px;
}

.team-item:hover {
  background: red;
}
.table-header tr {
  font-size: 12px;
  text-align: start;
}
tbody {
  margin-top: 50px;
}
.fixed {
  position: fixed;
  top: 180px;
}
</style>
