const state = {
  user: null,
};
const getters = {
  isLoggedIn(state) {
    return !!state.user;
  },
  getUser(state) {
    return state.user;
  },
};
const mutations = {
  LOGOUT(state) {
    state.user = null;
    sessionStorage.clear();
  },
  SET_USER(state, user) {
    state.user = user;
  },
};
const actions = {
  SET_USER(context, user) {
    context.commit("SET_USER", user);
  },
  LOGOUT(context) {
    context.commit("LOGOUT");
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
