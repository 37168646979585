<template>
  <div class="add-user">
    <form class="add-user-form" @submit.prevent>
      <div>
        <ImageUploader ref="image" />
        <div class="d-flex">
          <input
            type="text"
            class="form-control"
            v-model="user.firstName"
            required
            style="margin-right: 10px"
            placeholder="First Name"
          />
          <input
            type="text"
            required
            class="form-control"
            v-model="user.lastName"
            placeholder="Last Name"
          />
        </div>

        <div class="d-flex">
          <input
            type="text"
            style="margin-right: 10px"
            required
            class="form-control"
            v-model="user.phoneNumber"
            placeholder="Phone Number"
          />
          <input
            type="email"
            required
            v-model="user.email"
            class="form-control"
            placeholder="Email"
          />
        </div>
        <div class="d-flex justify-content-between">
          <input
            type="text"
            class="form-control"
            style="margin-right: 20px"
            v-model="user.designation"
            placeholder="Designation"
          />
          <multiselect
            v-model="user.role"
            :options="systemRoles"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            placeholder="Pick System Role"
            label=""
            track-by=""
            :preselect-first="false"
          >
            >
          </multiselect>
        </div>
      </div>

      <multiselect
        v-model="roles"
        :options="$store.state.teams"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Pick Teams"
        label="name"
        track-by="name"
        :preselect-first="true"
      >
        >
      </multiselect>
      <div style="margin-top: 40px" class="d-flex action-buttons">
        <button
          style="margin-right: 5px"
          :disabled="loading"
          class="btn primary"
          @click="addNewUser"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Create</span>
        </button>
        <input
          type="button"
          style="margin-left: 5px"
          class="btn destructive"
          value="Don't Save"
          @click="onCancel"
        />
      </div>
    </form>
  </div>
</template>
<script>
import ImageUploader from "../../components/ImageUploader.vue";
import ikebana from "../../services/ikebana";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ImageUploader,
    Multiselect,
  },
  data() {
    return {
      loading: false,
      roles: [],
      systemRoles: ["Admin", "Manager", "User"],
      user: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        designation: null,
        team: null,
        role: null,
      },
    };
  },
  methods: {
    addNewUser() {
      this.loading = true;
      let formData = new FormData();
      formData.append("avatar", this.$refs.image.imageFile);
      formData.append("firstName", this.user.firstName);
      formData.append("lastName", this.user.lastName);
      formData.append("designation", this.user.designation);
      formData.append("phoneNumber", this.user.phoneNumber);
      formData.append("email", this.user.email);
      formData.append("role", this.user.role);
      this.roles.forEach((role) => {
        formData.append("team", role.id);
      });
      ikebana.addNewUser(formData).then(
        (response) => {
          this.loading = false;
          this.$emit("hide");
          this.$store.dispatch("ADD_NEW_USER", response.data);
        },
        (error) => {
          this.$emit("hide");
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    onCancel() {
      this.$emit("hide");
    },
  },
};
</script>
<style lang="css"></style>
