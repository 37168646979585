<template>
  <div class="dashboard">
    <div class="search">
      <input type="text" name="" id="" placeholder="Search" />
    </div>
    <div class="row">
      <div class="col-md">
        <button class="nav-item" @click="$router.push('/order')">
          <span class="">
            <b-icon scale="2" class="nav-icon" icon="file-ruled"></b-icon>
            <br />
            <br />
            <span style="font-size: 14px">Orders</span></span
          >
        </button>
      </div>
      <div class="col-md">
        <button class="nav-item" @click="$router.push('/users')">
          <span class="">
            <b-icon scale="2" class="nav-icon" icon="person-badge"></b-icon>
            <br />
            <br />
            <span style="font-size: 14px">Users</span></span
          >
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button class="nav-item" @click="$router.push('/items')">
          <span class="">
            <b-icon scale="2" class="nav-icon" icon="calendar2-check"></b-icon>
            <br />
            <br />
            <span style="font-size: 14px"> Items</span></span
          >
        </button>
      </div>
      <div class="col-md">
        <button class="nav-item" @click="$router.push('/clients')">
          <span class="">
            <b-icon scale="2" class="nav-icon" icon="people"></b-icon>
            <br />
            <br />
            <span style="font-size: 14px"> Clients</span></span
          >
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button class="nav-item" @click="$router.push('/setting')">
          <span class="">
            <b-icon scale="2" class="nav-icon" icon="gear"></b-icon>
            <br />
            <br />
            <span style="font-size: 14px"> Settings</span></span
          >
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    navigateTo(page) {
      this.pageId = page.id;
      this.$router.push(page.to);
    },
  },
  created: function () {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      // this.$router.push("/order/list");
      return false;
    }
  },
};
</script>
<style lang="css" scoped>
.search input {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  margin-bottom: 10px;
}
.sidebar-container {
  width: 100px !important;
  background: white;
  z-index: 100 !important;
  padding: 0;
}
.sidebar {
  top: 0;
  padding-top: 15px !important;
  position: sticky;
  z-index: 100 !important;
}
.logo {
  background: url("/logo.png") no-repeat;
  background-size: contain;
  cursor: pointer;
  min-height: 100px;
  min-width: 100px;
  margin-left: 15px !important;
}
.nav-items {
  margin-top: 40px;
}
.nav-item {
  margin-bottom: 10px !important;
  background: white;
  color: #ad1f23;
  border-radius: 10px;
  line-height: 1em;
  width: 100% !important;
  height: 50vh !important;
}

.route-active {
  background: #ad1f23;
  color: white;
}
</style>
