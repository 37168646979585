<template>
  <div v-if="order" class="packing-info">
    <div class="order-item-list">
      <div v-for="(box, index) in box" :key="box + index" class="box">
        <div class="d-flex justify-content-between">
          <h6>{{ index === 0 ? "Default (Box 1)" : "Box " + (index + 1) }}</h6>
          <button v-if="index === 0" class="add" @click="add" type="button">
            <span>
              <b-icon scale="1" icon="plus"></b-icon>
              Add Box
            </span>
          </button>
          <button
            v-else
            class="remove-box"
            @click="remove(index)"
            type="button"
          >
            <span>
              <b-icon scale="1" icon="dash"></b-icon>
              Remove
            </span>
          </button>
        </div>
        <div
          v-for="(item, _index) in box.items"
          :key="_index"
          class="d-flex"
          style="margin-bottom: 5px"
        >
          <div class="d-flex">
            <div style="width: 100% !important">
              <img class="item-img" :src="item.image" />
              <input
                type="text"
                tabindex="-1"
                style="padding-left: 50px; width: 100% !important"
                disabled
                v-model="item.itemName"
              />
            </div>
            <div>
              <input
                disabled
                style="width: 100% !important"
                tabindex="-1"
                type="text"
                :value="item.qty + ' - ' + item.unit"
              />
            </div>
            <div>
              <input
                style="width: 100% !important"
                placeholder="Qty"
                type="number"
                v-show="index === 0"
                v-model="item.new_qty"
                tabindex="1"
              />
            </div>
            <div>
              <button
                @click="down(item, box, index, _index)"
                type="button"
                tabindex="-1"
                class="remove"
              >
                <b-icon scale="1.5" icon="arrow-down"></b-icon>
              </button>
            </div>
            <div>
              <button
                @click="up(item, box, index, _index)"
                v-show="index > 0"
                tabindex="-1"
                type="button"
                class="remove"
              >
                <b-icon scale="1.5" icon="arrow-up"></b-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row add-weight my-2">
        <label class="text-start col-3" for="weight">Weight: </label>
        <input
          required
          class="col"
          v-model="weight"
          name="weight"
          type="text"
          tabindex="2"
          style="font-size: 16px"
          placeholder="Total Weight with Unit (eg. 10Kg)"
        />
      </div>
      <div class="attach-image">
        <div class="d-flex justify-content-between">
          <div>
            <h6 class="text-start">Add Package Image(s)</h6>
          </div>
          <div>
            <input
              type="file"
              @change="onFileChange"
              multiple
              accept="image/png, image/gif, image/jpeg"
              class="file-input"
            />
          </div>
        </div>
        <div class="attachments">
          <img
            class="attachment"
            v-for="(image, index) in images"
            :key="index"
            :src="image"
          />
          <p class="caption">
            These attachments will be visible to the client.
          </p>
        </div>
      </div>
      <div class="add-note">
        <h6 class="text-start">Note:</h6>
        <textarea
          name="remarks"
          id=""
          cols="30"
          placeholder="Add note"
          v-model="note"
          required
          tabindex="3"
          style="
            width: 100%;
            resize: none;
            border: 1px solid #e9e9e9;
            border-radius: 12px;
          "
          rows="3"
        ></textarea>
        <p class="caption">This note will be visible to the client.</p>
      </div>
      <div class="d-flex action-buttons">
        <button
          type="submit"
          style="margin-right: 5px; background: #1aa100 !important"
          :disabled="loading"
          class="btn primary"
          @click="save"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Finish Packing</span>
        </button>
        <button
          type="button"
          style="margin-left: 5px"
          class="btn destructive"
          @click="onCancel"
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ikebana from "../../services/ikebana";
export default {
  props: {
    order: Object,
  },
  data() {
    return {
      weight: null,
      images: [],
      files: [],
      note: null,
      box: [
        {
          items: [],
        },
      ],
      loading: false,
    };
  },
  components: {},
  methods: {
    save() {
      let formData = new FormData();
      if (this.files.length === 0) {
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: "Add packed item photos",
        });
        return;
      }
      this.files.forEach((file) => {
        formData.append("Attachments", file);
      });
      var boxes = this.box.filter((x) => x.items.length > 0);
      formData.append("NumberOfBox", boxes.length);
      formData.append("Weight", this.weight);
      formData.append("Note", this.note);
      this.loading = true;
      ikebana.finishedPacking(this.order.id, formData).then(
        (response) => {
          this.loading = false;
          this.order.team = response.data.team;
          this.$notify({
            duration: 5000,
            group: "success",
            title: "Important message",
            text: "Finished packing",
          });
          this.$emit("endAction", response);
        },
        (error) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    onCancel() {
      this.$emit("hide");
    },
    onFileChange(e) {
      e.target.files.forEach((file) => {
        var image = URL.createObjectURL(file);
        this.images.push(image);
        this.files.push(file);
        this.order.attachments.push({
          path: image,
        });
      });
    },
    add() {
      this.box.push({
        items: [],
      });
    },
    remove(index) {
      if (this.box[index].items.length > 0) {
        this.$notify({
          duration: 5000,
          group: "warning",
          title: "Remove all items",
          text: "Please remove all items before removing the box",
        });
        return;
      }
      this.box.splice(index, 1);
    },
    up(item, box, b_index, i_index) {
      var b_items = this.box[b_index - 1].items;
      var existingItem = b_items.find((i) => i.id === item.id);
      if (existingItem !== null) {
        this.box[b_index - 1].items.find((x) => x.id === item.id).qty += Number(
          item.qty
        );
        box.items.splice(i_index, 1);
      } else {
        b_items.push(item);
      }
      if (item.qty - item.new_qty === 0) {
        box.items.splice(i_index, 1);
      } else {
        item.qty -= item.new_qty;
      }
      if (box.items.length === 0) {
        this.remove(b_index);
      }
    },
    down(item, box, b_index, i_index) {
      if (item.new_qty === null) {
        item.new_qty = item.qty;
      }

      if (item.new_qty < 0) {
        this.$notify({
          duration: 5000,
          group: "warning",
          title: "Error",
          text: "Qty cannot be less 1 the original order quantity",
        });
        return;
      }
      if (item.new_qty > item.qty) {
        this.$notify({
          duration: 5000,
          group: "warning",
          title: "Error",
          text: "Box qty cannot be less than the original order quantity",
        });
        return;
      }
      if (this.box[b_index + 1] === undefined) {
        this.box.push({
          items: [],
        });
      }

      if (item.qty - item.new_qty === 0) {
        box.items.splice(i_index, 1);
      } else {
        item.qty -= item.new_qty;
      }
      this.box[b_index + 1].items.push({
        id: item.id,
        image: item.image,
        itemId: item.itemId,
        itemName: item.itemName,
        qty: item.new_qty,
        new_qty: item.new_qty,
        unit: item.unit,
      });
      if (box.items.length === 0) {
        this.remove(b_index);
      }

      item.new_qty = null;
    },
  },
  mounted: function () {
    this.order.items.forEach((item) => {
      this.box[0].items.push({
        id: item.product.id,
        image: item.product.image,
        itemId: item.itemId,
        itemName: item.product.name,
        originalQty: item.qty,
        qty: item.qty,
        new_qty: null,
        unit: item.product.unit,
      });
    });
  },
};
</script>
<style lang="css" scoped>
label {
  color: #ad1f23 !important;
}
.action-buttons button {
  height: 50px;
}
.attachments {
  width: 100% !important;
  white-space: nowrap;

  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.attachment {
  height: 20vh;
  border-radius: 12px;
  margin-right: 10px;
}
.file-input {
  width: 110px !important;
  height: 35px !important;
  background: #008cf6 !important;
  color: white;
  background-size: cover;
}

.file-input::-webkit-file-upload-button {
  visibility: hidden;
  width: 100%;
}
.file-input::before {
  content: "Add Image";
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 12pt;
  z-index: 9999;
}
.file-input:hover::before {
  border-color: black;
}
.caption {
  font-size: 12px;
  text-align: start;
}
input {
  font-size: 13px;
}
.add {
  background: transparent;
  color: #008cf6;
}
.remove-box {
  background: transparent;
  color: #d40007;
}
.box {
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  min-height: 100px;
  margin-bottom: 10px;
}
.remove {
  background: white;
  color: #d40007;
  height: 50px;
  width: 50px;
  border: 1px solid #e8e8e8;
}
.unit {
  height: 50px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
}
.item-img {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 5px;
  border-radius: 25px;
  border: 1px solid #e8e8e8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input {
  margin: 0px;
  width: 100% !important;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
