<template>
  <div class="edit-user">
    <form class="edit-user-form" @submit.prevent>
      <div>
        <ImageUploader :image="user.avatar" ref="image" />
        <div class="d-flex">
          <input
            type="text"
            class="form-control"
            v-model="user.firstName"
            style="margin-right: 10px"
            required
            placeholder="First Name"
          />
          <input
            type="text"
            required
            class="form-control"
            v-model="user.lastName"
            placeholder="Last Name"
          />
        </div>
        <div class="d-flex">
          <input
            type="text"
            style="margin-right: 10px"
            required
            class="form-control"
            v-model="user.phoneNumber"
            placeholder="Phone Number"
          />
          <input
            type="email"
            required
            v-model="user.emailAddress"
            class="form-control"
            placeholder="Email"
            disabled
          />
        </div>
        <div class="d-flex justify-content-between">
          <input
            type="text"
            class="form-control"
            style="margin-right: 20px"
            v-model="user.designation"
            placeholder="Designation"
          />
          <multiselect
            v-model="user.role"
            :options="systemRoles"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            placeholder="Pick System Role"
            label=""
            track-by=""
            :preselect-first="false"
          >
            >
          </multiselect>
        </div>
      </div>

      <div style="padding-bottom: 25px">
        <multiselect
          v-model="roles"
          :options="$store.state.teams"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Pick Teams"
          :taggable="true"
          label="name"
          track-by="name"
          :preselect-first="false"
        />
      </div>
      <div class="d-flex action-buttons">
        <button
          style="margin-right: 5px"
          :disabled="loading"
          class="btn primary"
          @click="editUser"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Save</span>
        </button>
        <input
          type="button"
          style="margin-left: 5px"
          class="btn destructive"
          value="Cancel"
          @click="onCancel"
        />
      </div>
    </form>
  </div>
</template>
<script>
import ImageUploader from "../../components/ImageUploader.vue";
import Multiselect from "vue-multiselect";
import ikebana from "../../services/ikebana";
export default {
  props: {
    user: Object,
  },
  components: {
    ImageUploader,
    Multiselect,
  },
  data() {
    return {
      systemRoles: ["Admin", "Manager", "User"],
      roles: [],
      loading: false,
    };
  },
  methods: {
    editUser() {
      this.loading = true;
      let formData = new FormData();
      formData.append("Avatar", this.$refs.image.imageFile);
      formData.append("FirstName", this.user.firstName);
      formData.append("LastName", this.user.lastName);
      formData.append("Designation", this.user.designation);
      formData.append("PhoneNumber", this.user.phoneNumber);
      formData.append("Email", this.user.email);
      formData.append("Id", this.user.id);
      formData.append("role", this.user.role);
      this.roles.forEach((role) => {
        formData.append("Team", role.id);
      });
      ikebana.editUser(this.user.id, formData).then(
        (response) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "success",
            title: "Important message",
            text: "User information has been updated",
          });
          this.$store.commit("GET_USERS", response.data.data);
          this.$emit("hide");
        },
        (error) => {
          this.loading = false;
          this.$emit("hide");
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    onCancel() {
      this.$emit("hide");
    },
  },
  mounted: function () {
    this.user.teams.forEach((team) => {
      this.roles.push(team);
    });
  },
};
</script>
<style lang="css"></style>
