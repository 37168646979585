<template>
  <div class="login-layout">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="css">
.login-layout {
  height: 100vh;
  width: 100%;
  background: url("../../assets/ikebana_bg.jpg") no-repeat;
  background-size: cover;
}
</style>
