/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  self.addEventListener("message", (event) => {
    if (event.data && event.data.type === "SKIP_WAITING") {
      self.skipWaiting();
    }
  });
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.log(
      //   "App is being served from cache by a service worker.\n" +
      //     "For more details, visit https://goo.gl/AFskqB"
      // );
    },
    registered() {
      //console.log("Service worker has been registered.");
    },
    cached() {
      //console.log("Content has been cached for offline use.");
    },
    updatefound() {
      // new content clear cache so user gets the new version
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    },
    updated() {
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: register })
      );
    },
    offline() {
      // console.log(
      //   "No internet connection found. App is running in offline mode."
      // );
    },
    // eslint-disable-next-line no-unused-vars
    error(error) {
      //console.error("Error during service worker registration:", error);
    },
  });
}
