<template>
  <div>
    <div v-if="!$store.state.loading.clients">
      <div v-if="$route.name === 'Clients'" class="clients">
        <Sidebar
          :title="
            this.$store.state.edit.client !== null
              ? 'Edit Client'
              : 'Add Client'
          "
          ref="sidebar"
        >
          <EditClient
            @hide="hide"
            :client="this.$store.state.edit.client"
            v-if="this.$store.state.edit.client !== null"
          />
          <AddClient @hide="hide" v-else />
        </Sidebar>
        <ClientList @show="show" v-if="$store.state.clients.length > 0" />
        <Add :action="addClient" :icon="'plus'" />
      </div>
      <div v-else>
        <transition name="slide-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
    <b-spinner v-else class="spinner"></b-spinner>
  </div>
</template>
<script>
import Add from "../../components/Add.vue";
import EditClient from "./EditClient.vue";
import AddClient from "./AddClient.vue";
import Sidebar from "../../components/Sidebar.vue";
import ClientList from "../../components/ClientList.vue";
export default {
  components: {
    ClientList,
    Add,
    EditClient,
    AddClient,
    Sidebar,
  },
  data() {
    return {};
  },
  methods: {
    addClient() {
      this.$refs.sidebar.open();
    },
    hide() {
      this.$refs.sidebar.close();
    },
    show() {
      this.$refs.sidebar.open();
    },
  },
  mounted: function () {
    this.$store.dispatch("GET_CLIENTS");
  },
};
</script>
<style scoped>
.clients {
  margin-top: 10px;
}
.clients-list p {
  padding-left: 50px;
}
.controls input,
button {
  margin-left: 15px;
}
.clients p {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
input {
  width: 100%;
  padding-left: 15px;
}
</style>
