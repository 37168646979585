<template>
  <div ref="orderForm" class="add-order">
    <form @submit.prevent>
      <div>
        <!-- <ImageUploader /> -->
        <div class="d-flex">
          <label class="col-2" for="date">Date: </label>
          <input
            style="width: 100% !important"
            v-model="order.due"
            type="datetime-local"
            name="datetime"
          />
        </div>
        <div class="d-flex">
          <label class="col-2" for="transportMode">Transport Mode: </label>

          <multiselect
            style="margin-bottom: 10px"
            :options="[
              'Airport',
              'Airport Counter',
              'Airport Lounge',
              'Domestic',
              'TMA',
              'Hulhumale',
              'T Jetty',
              'Maldivian',
              'Manta Air',
              '1 No. Jetty',
              '5 No. Jetty',
              '6 No. Jetty',
              'Pickup by Customer',
              'To be collected from Shop',
              'Others',
            ]"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="false"
            placeholder="Transport Mode"
            :taggable="false"
            v-model="order.transportMethod"
            selectLabel=""
            :preselect-first="false"
          />
        </div>
        <div class="d-flex">
          <label class="col-2" for="invoiceId">Po Number: </label>
          <input
            required
            class="col"
            name="invoiceId"
            type="text"
            v-model="order.invoiceId"
            placeholder="Order number"
          />
        </div>

        <transition name="slide-fade" mode="out-in">
          <div class="d-flex">
            <label class="col-2" for="text">Client:</label>
            <!-- <AutoComplete
              :items="$store.state.clients"
              placeholder="Add Client"
              @search="selectClient($event)"
            ></AutoComplete> -->
            <!-- <multiselect
              v-model="client"
              placeholder="Add Client"
              :options="$store.state.clients"
              :searchable="true"
              selectLabel=""
              label="name"
              track-by="name"
              :allow-empty="true"
              :preselect-first="false"
              @select="selectClient"
            >
            </multiselect> -->

            <multiselect
              v-model="client"
              track-by="name"
              deselectLabel=""
              selectLabel=""
              @select="selectClient"
              :showNoResults="false"
              openDirection="bottom"
              label="name"
              :searchable="true"
              placeholder="Add Client"
              :options="$store.state.clients"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.name }}</strong>
              </template>
            </multiselect>
          </div>
        </transition>
        <div style="min-height: 10px"></div>
        <div v-if="order.client !== null" class="d-flex">
          <multiselect
            v-model="correspondents"
            placeholder="Add Correspondents"
            :options="client.correspondents"
            :searchable="true"
            :preselect-first="false"
            label="name"
            :close-on-select="false"
            :clear-on-select="true"
            track-by="name"
            selectLabel=""
            :multiple="true"
            :allow-empty="true"
            :taggable="true"
          >
          </multiselect>
        </div>
        <div style="min-height: 10px"></div>

        <!-- 
        <AutoComplete
          placeholder="Search Items"
          :items="this.$store.state.items"
          @search="selectItem($event)"
        ></AutoComplete> -->
        <multiselect
          :options="$store.state.items"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Search Items"
          :block-keys="['Tab']"
          :taggable="false"
          :custom-label="itemWithSize"
          track-by="name"
          @select="selectItem"
          selectLabel=""
          :preselect-first="false"
        />
        <label style="width: 100%">Items:</label>

        <div class="order-item-list-container">
          <p v-show="this.order.items.length === 0">Please add items</p>
          <div class="order-item-list">
            <NewItem
              v-for="(item, index) in this.order.items"
              :key="index"
              :item="item"
              @remove="remove"
            />
          </div>
        </div>
        <div class="my-2">
          <label style="width: 100%">Customer note:</label>
          <input
            required
            style="width: 100% !important"
            class="col"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            name="invoiceId"
            type="text"
            maxlength="50"
            v-model="order.customerNote"
            placeholder="Customer note"
          />
        </div>
        <div>
          <label style="width: 100%">Additional notes:</label>
          <textarea
            name="remarks"
            style="
              width: 100%;
              height: 70px;
              resize: none;
              border: 1px solid #e8e8e8;
              border-radius: 12px;
            "
            v-model="note"
            rows="5"
          ></textarea>
        </div>
        <div>
          <label style="width: 100%">Reference Images:</label>
          <input
            ref="files"
            style="width: 100% !important"
            @change="fileSelected"
            multiple="multiple"
            type="file"
          />
        </div>
      </div>
      <!-- <button class="btn destructive delete-button">Delete Item</button> -->
      <div class="row">
        <div class="col-md">
          <button :disabled="loading" class="btn primary" @click="saveOrder">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading">Create</span>
          </button>
        </div>
        <div class="col-md">
          <button type="button" class="btn destructive" @click="dontSave">
            Dont Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import NewItem from "../../components/NewItem.vue";
import Ikebana from "../../services/ikebana";

export default {
  components: {
    NewItem,
    Multiselect,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      dueTime: null,
      loading: false,
      itemName: "",
      client: null,
      correspondents: null,
      files: [],
      note: "",
    };
  },

  methods: {
    itemWithSize({ name, size }) {
      return name + " - " + size;
    },
    remove(item) {
      console.log(item);
      var _item = this.order.items.find((i) => i.id === item.id);
      this.order.items.splice(this.order.items.indexOf(_item), 1);
    },
    scrollToEnd: function () {
      // scroll to the start of the last message
      this.$el.scrollTop = this.$el.lastElementChild.offsetTop;
    },
    selectItem(item) {
      console.log(item);
      this.scrollToEnd();
      var _item = this.order.items.find((i) => i.ProductId === item.id);
      if (_item) {
        _item.qty++;
      } else {
        this.order.items.push({
          productId: item.id,
          product: item,
          productSize: item.size,
          size: item.size,
          qty: 1,
        });
      }
    },
    selectClient(client) {
      this.client = client;
      this.order.client = client.id;
    },
    search_client() {
      if (this.$store.state.clients.length === 0) {
        this.$store.dispatch("GET_CLIENTS");
      }

      var final = this.$store.state.clients.filter((item) =>
        item.name.includes(this.client)
      );

      if (final.length === 1) {
        this.order.client = final[0].id;
      }
    },
    fileSelected(e) {
      e.target.files.forEach((element) => {
        this.files.push(element);
      });
    },

    saveOrder() {
      let formData = new FormData();
      this.files.forEach((element) => {
        formData.append("Reference", element);
      });

      if (this.correspondents.length === 0) {
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: "Please select a correspondent",
        });
        return;
      }

      if (this.client === null) {
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: "Please select a client",
        });
        return;
      }

      if (this.order.due === null) {
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: "Please select a delivery date",
        });
        return;
      }
      formData.append("Due", this.order.due);
      formData.append("OrderId", this.order.invoiceId);
      formData.append("ClientId", this.order.client);
      formData.append("AdditionalNote", this.note);
      formData.append("TransportationMode", this.order.transportMethod);

      if (this.order.customerNote) {
        formData.append("CustomerNote", this.order.customerNote);
      }

      this.correspondents.forEach((element, index) => {
        formData.append("CorrespondentIds" + "[" + index + "]", element.id);
      });

      this.order.items.forEach((element, index) => {
        formData.append(
          "Items" + "[" + index + "].ProductId",
          element.productId
        );
        formData.append("Items" + "[" + index + "].qty", element.qty);
        formData.append("Items" + "[" + index + "].size", element.size);
      });
      this.order.status = "New Order";
      this.loading = true;
      Ikebana.addOrder(formData).then(
        (response) => {
          this.loading = false;
          this.$store.commit("ADD_NEW_ORDER", response.data);
          this.$emit("hide");
          document.documentElement.style.overflow = "auto";
          // this.$router.push({
          //   name: "Order Details",
          //   params: {
          //     id: response.data.id,
          //   },
          // });
          this.client = null;
          this.note = "";
        },
        (error) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    dontSave() {
      this.$emit("hide");
    },
    setClient() {
      this.order.logo = "/red_rose.png";
      this.order.client = "Waldorf";
    },
    removeSelectedClient() {
      this.client = null;
      this.order.client = null;
    },
  },
  watch: {
    dueTime: function () {
      const totalLength = this.dueTime.length;
      let a = this.dueTime.replace(/[^0-23]/g, "").substr(0, 2);

      const b = this.dueTime
        .replace(/[^0-59]/g, "") // 1. remove anything not 0 through 5
        .substr(1, 1); // 2. take 2nd character of result

      const c = this.dueTime.replace(/[^0-9]/g, "").substr(2, 1);
      if (totalLength >= 2) {
        a = `${a.substring(0, 1)}:${b}${c}`;
      }
      this.dueTime = a;
    },
  },
  mounted: function () {},
};
</script>
<style lang="css" scoped>
.order-item-list-container {
  border: 1px solid #e8e8e8;
  padding-top: 5px !important;
  border-radius: 10px !important;
  min-height: 45px;
}
.order-item-list {
  table-layout: fixed;
  width: 100%;
}
.order-item-list tr:hover {
  background: transparent;
}
.selected-client div {
  padding-top: 5px;
}
.selected-client {
  vertical-align: middle;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
}

.input-w-suffix {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.input-group-addon {
  padding: 15px 20px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #ad1f23;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #e8e8e8;
  width: 2%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}
label {
  padding-right: 10px !important;
  color: #ad1f23;
  text-align: start !important;
  width: 20%;
}
.delete-button {
  border: none !important;
  margin-top: -100px;
}

.add-order {
  height: 100%;
  padding-top: 10px;
}
form {
  padding-left: 10px;
  height: 100% !important;
  padding-right: 10px;
}
@media (max-width: 767.98px) {
  .add-order-form {
    padding: 10px !important;
  }
}
input {
  margin-bottom: 10px;
  height: 50px;
}
</style>
