<template>
  <div class="col-md-3" style="padding: 10px">
    <button style="width: 100%" @click="showEdit" v-b-toggle.sidebar-border>
      <div class="user">
        <a>
          <img width="100" height="100" class="avatar" :src="user.avatar" />
          <h5 class="m-b-xs">
            <strong
              >{{ user.firstName }} <br />
              {{ user.lastName }}</strong
            >
          </h5>

          <div>{{ user.designation }}</div>
          <div>{{ user.phoneNumber }}</div>
          <div>{{ user.emailAddress }}</div>
        </a>
        <!-- <div>
        <span
          v-for="(t, index) in user.teams"
          :key="index"
          class="multiselect__tag"
          ><span>{{ t.name }}</span>
        </span>
      </div> -->
      </div>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    user: Object,
    first: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      value: null,
    };
  },
  methods: {
    showEdit() {
      this.$store.commit("SET_EDIT_USER", this.user);
    },
  },
  components: {},
};
</script>
<style lang="css" scoped>
.avatar {
  border-radius: 50px;
}

button {
  padding: 0;
}

.user:hover {
  background: #ccc;
}
.user {
  border-radius: 12px !important;
  background: white;
  border: 1px solid #e8e8e8;
  transition: all 0.2s;
}

.badge {
  margin: 5px;
}
</style>
