<template>
  <div class="add-client">
    <form class="add-client-form" @submit.prevent="addNewCorrespondent">
      <div>
        <input
          type="text"
          class="form-control"
          placeholder="Correspondent Name"
          required
          v-model="correspondent.name"
        />
        <div class="d-flex">
          <input
            type="email"
            class="form-control"
            style="margin-right: 10px"
            required
            placeholder="Email Address"
            v-model="correspondent.email"
          />
          <input
            type="text"
            class="form-control"
            required
            placeholder="Department"
            v-model="correspondent.department"
          />
        </div>
        <div class="d-flex">
          <input
            required
            type="text"
            style="margin-right: 10px"
            v-model="correspondent.designation"
            class="form-control"
            placeholder="Designation"
          />
        </div>
        <input
          type="number"
          class="form-control"
          v-model="correspondent.phoneNumber"
          placeholder="Phone Number"
        />
        <div class="d-flex">
          <button
            type="submit"
            style="margin-right: 5px"
            :disabled="loading"
            class="btn primary"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading">Add</span>
          </button>
          <button
            type="button"
            style="margin-right: 5px"
            class="btn destructive"
            @click="onCancel"
          >
            <span v-show="!loading">Cancel</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import ikebana from "../../services/ikebana";

export default {
  props: {
    client: Object,
  },
  components: {},
  data() {
    return {
      loading: false,
      correspondent: {
        name: null,
        designation: null,
        email: null,
        phoneNumber: null,
        department: null,
      },
    };
  },
  methods: {
    addNewCorrespondent() {
      this.loading = true;
      ikebana.addNewCorrespondent(this.client.id, this.correspondent).then(
        (response) => {
          this.loading = false;
          this.client.correspondents.push(response.data);
          this.correspondent.name = null;
          this.correspondent.designation = null;
          this.correspondent.email = null;
          this.correspondent.phoneNumber = null;
          this.correspondent.department = null;
          this.$emit("hide");
        },
        (error) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    onCancel() {
      this.$emit("hide");
    },
  },
};
</script>
<style lang="css" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
