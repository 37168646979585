<template>
  <div v-if="deferredPrompt" class="banner">
    <div class="row">
      <div class="col-md">
        <p class="text-start text-white">
          Install this App for better experience
        </p>
      </div>
      <div class="col-md-2">
        <div class="d-flex">
          <button class="btn mx-2" text @click="install">Install</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>

<style scoped>
.banner {
  z-index: 99999;
  background: #ad1f23;
  margin-left: auto;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}
button {
  background: white;
  color: #ad1f23;
}
</style>
