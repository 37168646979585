<template>
  <div class="edit-client">
    <form class="edit-client-form" @submit.prevent="addNewItem">
      <div>
        <ImageUploader :image="client.logo" ref="image" />
        <input
          type="text"
          class="form-control"
          placeholder="Client Name"
          required
          v-model="client.name"
        />
      </div>
      <div class="d-flex">
        <button
          style="margin-right: 5px"
          :disabled="loading"
          class="btn primary"
          @click="addNewClient"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Save</span>
        </button>
        <button
          type="button"
          style="margin-right: 5px"
          class="btn destructive"
          @click="onCancel"
        >
          <span v-show="!loading">Cancel</span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import ImageUploader from "../../components/ImageUploader.vue";
import ikebana from "../../services/ikebana";

export default {
  props: {
    client: Object,
  },
  components: {
    ImageUploader,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    addNewClient() {
      this.loading = true;
      let formData = new FormData();
      formData.append("logo", this.$refs.image.imageFile);
      formData.append("name", this.client.name);
      formData.append("correspondent", this.client.correspondent);
      formData.append("designation", this.client.designation);
      formData.append("email", this.client.email);
      formData.append("phoneNumber", this.client.phoneNumber);
      this.loading = true;
      ikebana.editClient(this.client.id, formData).then((response) => {
        this.loading = false;
        this.$store.commit("REMOVE_CLIENT", response.data.data);
        this.$emit("hide");
      });
    },
    onCancel() {
      this.$emit("hide");
    },
  },
};
</script>
<style lang="css"></style>
