<template>
  <div
    class="col-md-1 sidebar-container"
    :class="collapsed ? 'collapsed' : 'not-collapsed'"
  >
    <div>
      <b-sidebar
        class="bg-dark"
        style="background: red"
        id="sidebar-backdrop"
        title=""
        :backdrop-variant="variant"
        backdrop
        no-header
        shadow
      >
        <div class="">
          <div
            style="
              width: 100%;
              top: 0;
              left: 0;
              bottom: 0;
              height: 100%;
              border-radius: 0px;
              margin: 0;
            "
            class="sidebar d-md-none d-md-block"
          >
            <ul class="nav_list">
              <li v-for="sidebaritem in sidebar" :key="sidebaritem.id">
                <router-link :to="sidebaritem.to">
                  <b-icon
                    class="nav-icon"
                    :icon="sidebaritem.icon"
                    shift-v="0"
                  ></b-icon>
                  <span class="links_name">{{ sidebaritem.name }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </b-sidebar>
    </div>
    <!-- @mouseover="collapsed = false"
      @mouseleave="collapseSidebar()" -->
    <div class="sidebar d-none d-md-block">
      <div @click="$router.push('/')" class="logo"></div>
      <div class="nav-items">
        <button
          v-for="nav in sidebar"
          :key="nav.id"
          class="nav-item"
          :class="pageId === nav.id ? 'route-active' : 'route-not-active'"
          @click="navigateTo(nav)"
        >
          <span class="">
            <b-icon scale="1.5" class="nav-icon" :icon="nav.icon"></b-icon>
            <br />
            <br />
            <span style="font-size: 14px"> {{ nav.name }}</span></span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageId: 1,
      collapsed: true,
      sidebar: [
        {
          id: 1,
          to: "/order",
          name: "Orders",
          icon: "file-ruled",
          active: false,
        },
        {
          id: 2,
          to: "/users",
          name: "Users",
          icon: "person-badge",
          active: false,
        },
        {
          id: 3,
          to: "/items",
          name: "Items",
          icon: "calendar2-check",
          active: false,
        },
        {
          id: 4,
          to: "/clients",
          name: "Clients",
          icon: "people",
          active: false,
        },
        {
          id: 5,
          to: "/setting",
          name: "Settings",
          icon: "gear",
          active: false,
        },
      ],
      variant: "dark",
    };
  },
  methods: {
    navigateTo(page) {
      this.pageId = page.id;
      this.$router.push(page.to);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/auth/login");
    },
    collapseSidebar() {
      setTimeout(() => (this.collapsed = true), 2000);
    },
  },
  mounted: function () {
    var route = this.$route.path;
    var item = this.sidebar.find((x) => x.to === route);
    if (item) {
      this.pageId = item.id;
    }
  },
};
</script>
<style lang="css" scoped>
.sidebar-container {
  width: 100px !important;
  background: white;
  z-index: 100 !important;
  padding: 0;
}
.sidebar {
  top: 0;
  padding-top: 15px !important;
  position: sticky;
  height: 100vh !important;
  z-index: 100 !important;
}
.logo {
  background: url("/logo.png") no-repeat;
  background-size: contain;
  cursor: pointer;
  min-height: 100px;
  min-width: 100px;
  margin-left: 15px !important;
}
.nav-items {
  margin-top: 40px;
}
.nav-item {
  margin-bottom: 10px !important;
  background: white;
  margin-right: auto !important;
  color: #ad1f23;
  border-radius: 10px;
  line-height: 1em;
  width: 90px !important;
  height: 90px !important;
}

.route-active {
  background: #ad1f23;
  color: white;
}
</style>
