<template>
  <div v-if="!loading" class="client-list row">
    <Sidebar
      @change="sidebarChanged($event)"
      :title="edit ? 'Edit Correspondent' : 'Add Correspondent'"
      ref="sidebar"
    >
      <AddCorrespondent v-if="!edit" @hide="hide" :client="selectedClient" />
      <EditCorrespondent
        v-else
        @hide="hide"
        :client="selectedClient"
        :correspondent="selectedCorrespondent"
      />
    </Sidebar>
    <div class="col-md-4">
      <div style="margin-bottom: 10px">
        <input
          style="width: 100%; height: 50px"
          type="text"
          placeholder="Search clients"
          v-model="searchClients"
        />
      </div>
      <div class="clients" v-if="clients.length > 0">
        <perfect-scrollbar>
          <button
            @click="selectClient(client)"
            v-for="(client, index) in filteredClients"
            :key="index"
            :class="selectedClient === client ? 'selected' : ''"
            class="d-flex justify-content-between client"
          >
            <div style="width: 80px">
              <img
                class="rounded-circle"
                width="50"
                height="50"
                :src="client.logo"
                alt=""
              />
            </div>
            <div style="width: 100%; padding-top: 10px">
              <p class="text-start">
                {{ client.name }}
              </p>
            </div>
            <div style="padding-top: 10px">
              <button @click="editClient" class="btn">
                <b-icon icon="pencil"></b-icon>
              </button>
            </div>
          </button>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="col-md correspondents">
      <div v-if="selectedClient">
        <div class="d-flex justify-content-between">
          <h6 v-if="selectedClient">
            Correspondents of {{ selectedClient.name }}
          </h6>
          <div class="d-flex">
            <button
              v-show="selectedCorrespondent"
              @click="removeCorrespondent"
              style="margin-right: 5px"
              class="btn destructive"
            >
              Delete
            </button>
            <button @click="addCorrespondent" class="btn primary">Add</button>
          </div>
        </div>
        <perfect-scrollbar
          v-if="
            (selectedClient.correspondents !== null) &
            (selectedClient.correspondents.length > 0)
          "
        >
          <table class="table">
            <thead>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Designation</th>
              <th>Department</th>
              <th></th>
            </thead>
            <tbody>
              <tr
                v-for="(correspondent, index) in selectedClient.correspondents"
                :key="index"
                @click="selectedCorrespondent = correspondent"
                :class="[
                  index === 0 ? 'first-row' : '',
                  correspondent === selectedCorrespondent ? 'selected' : '',
                ]"
              >
                <td class="start">
                  {{ correspondent.name }}
                </td>
                <td>
                  {{ correspondent.email }}
                </td>
                <td>
                  {{ correspondent.phoneNumber }}
                </td>
                <td>
                  {{ correspondent.designation }}
                </td>
                <td>
                  {{ correspondent.department }}
                </td>
                <td class="end">
                  <button @click="editCorrespondent(correspondent)">
                    <b-icon icon="pencil"></b-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
      <div class="empty-correspondents" v-else>
        <h6>Please selected a client</h6>
      </div>
    </div>
  </div>
  <b-spinner v-else class="spinner"></b-spinner>
</template>
<script>
import AddCorrespondent from "../views/Clients/AddCorrespondent.vue";
import EditCorrespondent from "../views/Clients/EditCorrespondent.vue";
import Sidebar from "./Sidebar.vue";
import ikebana from "../services/ikebana";
export default {
  components: {
    AddCorrespondent,
    EditCorrespondent,
    Sidebar,
  },
  data() {
    return {
      edit: false,
      clients: [],
      selectedClient: null,
      selectedCorrespondent: null,
      searchClients: "",
      loading: false,
    };
  },
  methods: {
    sidebarChanged(event) {
      if (event === false) {
        this.$store.dispatch("RESET_EDIT");
        this.edit = false;
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    editCorrespondent(c) {
      this.selectedCorrespondent = c;
      this.edit = true;
      this.$refs.sidebar.open();
    },
    editClient() {
      this.$store.commit("SET_EDIT_CLIENT", this.selectedClient);
      this.$emit("show");
    },
    selectClient(client) {
      this.selectedClient = client;
    },
    hide() {
      this.$refs.sidebar.close();
    },
    addCorrespondent() {
      this.$refs.sidebar.open();
    },
    removeCorrespondent() {
      ikebana
        .deletecorrespondent(
          this.selectedClient.id,
          this.selectedCorrespondent.id
        )
        .then(
          () => {
            this.selectedClient.correspondents.splice(
              this.selectedClient.correspondents.indexOf(
                this.selectedCorrespondent
              ),
              1
            );
          },
          (error) => {
            var err = error.response.data;
            this.$notify({
              duration: 5000,
              group: "error",
              title: err.Message,
              text: err.Data,
            });
          }
        );
    },
  },
  computed: {
    filteredClients() {
      if (this.selectedClient === "") {
        return this.clients;
      }
      return this.clients.filter((client) => {
        return client.name
          .toLowerCase()
          .match(this.searchClients.toLowerCase());
      });
    },
  },
  watch: {
    selectedClient: function () {
      this.selectedCorrespondent = null;
    },
  },
  mounted: function () {
    this.clients = this.$store.state.clients;
  },
  created: function () {
    this.loading = true;
    ikebana.getClients().then(
      (response) => {
        this.clients = response.data;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  },
};
</script>
<style lang="css" scoped>
.empty-correspondents {
  background: white;
  min-height: 88vh;
  border-radius: 12px;
}
.client-list {
  overflow: hidden !important;
}
.correspondents .ps {
  height: 88vh;
}
.clients .ps {
  max-height: 88vh;
}
.correspondents {
  min-height: 95vh;
  padding: 10px;
  top: 0;
  position: sticky;
}
.client {
  width: 100%;
  background: white;
  display: inline-block;
  margin-bottom: 10px !important;
}

.selected {
  background: gainsboro !important;
}
.client img {
  background-position: center;
  background-size: contain !important;
}
.first-row {
  margin-top: 100px;
}
img {
  margin-top: 5px;
}
p {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
.table-header tr {
  font-size: 12px;
}
tbody {
  margin-top: 50px;
}
.fixed {
  position: fixed;
  top: 180px;
}

tr {
  border: none;
}

.start {
  justify-content: start;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.end {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
th {
  font-weight: normal;
  font-size: 12px;
  border: none;
  text-align: start;
  padding-left: 10px;
}
</style>
