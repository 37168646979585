import Vue from "vue";
import store from "../store/index";
import VueRouter from "vue-router";
import MainLayout from "../views/Layout/Layout";
import AuthLayout from "../views/Layout/AuthLayout";
import Orders from "../views/Order/Orders.vue";
import Clients from "../views/Clients/List.vue";

import Tracking from "../views/Tracking.vue";
import DeliveryNote from "../views/Order/DeliveryNote.vue";
import Dashboard from "../views/Dashboard.vue";
import NewOrder from "../views/Order/New.vue";
import OrderList from "../views/Order/List.vue";
import OrderDetails from "../views/Order/Details.vue";
import ItemList from "../views/Items/List.vue";
import UserList from "../views/Users/List.vue";
import ClientOrders from "../views/Clients/ClientOrderList.vue";
import Settings from "../views/Setting/Profile.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/t/:id",
    name: "Tracking",
    props: true,
    component: Tracking,
  },
  {
    path: "/d/:id",
    name: "DeliveryNote",
    props: true,
    component: DeliveryNote,
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue"),
      },
      {
        path: "forgot",
        name: "Forgot",
        component: () =>
          import(/* webpackChunkName: "forgot" */ "../views/Auth/Forgot.vue"),
      },
      {
        path: "verify",
        name: "verify",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "auth-verify" */ "../views/Auth/Verify.vue"
          ),
      },
      {
        path: "reset",
        name: "reset",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "auth-reset" */ "../views/Auth/Reset.vue"
          ),
      },
    ],
  },
  {
    name: "Home",
    path: "",
    redirect: "/order/list",
    component: MainLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { authorize: ["Admin", "Manager", "User"] },
        component: Dashboard,
      },
      {
        path: "order",
        name: "Orders",
        meta: { authorize: ["Admin", "Manager", "User"] },
        redirect: "/order/list",
        component: Orders,
        children: [
          {
            path: "new",
            name: "New Order",
            meta: { authorize: ["Admin", "Manager", "User"] },
            props: true,
            component: NewOrder,
          },
          {
            name: "Order List",
            path: "list",
            meta: { authorize: ["Admin", "Manager", "User"] },
            component: OrderList,
          },
          {
            name: "Order Details",
            path: "details/:id",
            meta: { authorize: ["Admin", "Manager", "User"] },
            props: true,
            component: OrderDetails,
          },
        ],
      },
      {
        path: "/items",
        name: "Item List",
        meta: { authorize: ["Admin", "Manager", "User"] },
        component: ItemList,
      },
      {
        path: "/users",
        name: "Users",
        meta: { authorize: ["Admin", "Manager"] },
        component: UserList,
      },
      {
        path: "/clients",
        name: "Clients",
        component: Clients,
        meta: { authorize: ["Admin", "Manager"] },
        children: [
          {
            name: "Client Orders",
            path: ":id",
            props: true,
            meta: { authorize: ["Admin", "Manager", "User", "Client"] },
            component: ClientOrders,
          },
        ],
      },
      {
        path: "/setting",
        name: "Settings",
        meta: { authorize: ["Admin", "Manager", "User"] },
        component: Settings,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = store.getters.getUser;
  if (authorize) {
    if (!currentUser) {
      return next({ path: "/auth/login" });
    }
    if (!authorize.includes(currentUser?.role)) {
      return next({ path: "/auth/login", query: { returnUrl: to.path } });
    }
  }
  next();
});

export default router;
